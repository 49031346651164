import React from 'react';
import './App.css'
import 'bulma/bulma.sass'
import withSplashScreen from "./Components/withSplashScreen";
import Home from "./Components/Home";
import {Route, Router} from "react-router-dom";
import history from "./history";
import {CookiesProvider} from "react-cookie";

function App() {
    return (
        <CookiesProvider>
            <div className="App">
                <Router history={history}>
                    <Route path='/' component={Home}/>
                </Router>
            </div>
        </CookiesProvider>
    );
}

export default withSplashScreen(App);
