import React from 'react';
import styled from 'styled-components';
import {useAtom} from "jotai";
import {abstractAtom} from "../Atoms/abstract";
import {useResetAtom} from "jotai/utils";

const Title = styled.p`
  font-size: 1.1em;
  font-weight: 600;
  width: 100%;
`

const SubTitle = styled.p`
  width: 100%;
`

const Header = styled.header`
  flex-wrap: wrap;
`

export const AbstractView = () => {
    const [abstract] = useAtom(abstractAtom)
    const resetAbstract = useResetAtom(abstractAtom)

    const {content, title, authors} = abstract;

    const show = title !== '' ? 'modal is-active' : 'modal';
    const authorsStr = authors.join(', ');

    const onClose = () => {
        resetAbstract()
    }

    return (
        <div className={show}>
            <div className="modal-background" onClick={onClose}/>
            <div className="modal-card">
                <Header className="modal-card-head">
                    <Title>{title}</Title>
                    <SubTitle>{authorsStr}</SubTitle>
                </Header>
                <section className="modal-card-body">
                    {content}
                </section>
                <footer className="modal-card-foot">
                    <button onClick={onClose} className="button is-success">OK</button>
                </footer>
            </div>
        </div>
    )
}
