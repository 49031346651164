import React, {Component} from 'react';
import styled from 'styled-components';

const SplashScreen = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #1b2441;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#344674+0,324973+16,2f4a77+34,2f4f76+43,335475+47,355971+51,3d6370+57,3e646d+59,426c6b+63,46706c+66,46746a+70,538466+81,548862+84,609c60+97,639d62+100 */
  //background: rgb(52,70,116); /* Old browsers */
  //background: -moz-linear-gradient(top, rgb(52,70,116) 0%, rgb(50,73,115) 16%, rgb(47,74,119) 34%, rgb(47,79,118) 43%, rgb(51,84,117) 47%, rgb(53,89,113) 51%, rgb(61,99,112) 57%, rgb(62,100,109) 59%, rgb(66,108,107) 63%, rgb(70,112,108) 66%, rgb(70,116,106) 70%, rgb(83,132,102) 81%, rgb(84,136,98) 84%, rgb(96,156,96) 97%, rgb(99,157,98) 100%); /* FF3.6-15 */
  //background: -webkit-linear-gradient(top, rgb(52,70,116) 0%,rgb(50,73,115) 16%,rgb(47,74,119) 34%,rgb(47,79,118) 43%,rgb(51,84,117) 47%,rgb(53,89,113) 51%,rgb(61,99,112) 57%,rgb(62,100,109) 59%,rgb(66,108,107) 63%,rgb(70,112,108) 66%,rgb(70,116,106) 70%,rgb(83,132,102) 81%,rgb(84,136,98) 84%,rgb(96,156,96) 97%,rgb(99,157,98) 100%); /* Chrome10-25,Safari5.1-6 */
  //background: linear-gradient(to bottom, rgb(52,70,116) 0%,rgb(50,73,115) 16%,rgb(47,74,119) 34%,rgb(47,79,118) 43%,rgb(51,84,117) 47%,rgb(53,89,113) 51%,rgb(61,99,112) 57%,rgb(62,100,109) 59%,rgb(66,108,107) 63%,rgb(70,112,108) 66%,rgb(70,116,106) 70%,rgb(83,132,102) 81%,rgb(84,136,98) 84%,rgb(96,156,96) 97%,rgb(99,157,98) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#344674', endColorstr='#639d62',GradientType=0 ); /* IE6-9 */
`;

const SplashScreenImage = styled.img`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  overflow: auto;
`;

function LoadingMessage() {
    return (
        <SplashScreen>
            <SplashScreenImage src="/splashscreen.jpg" alt="KomPlasTech 2021 program is loading..."/>
        </SplashScreen>
    );
}

function withSplashScreen(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                loading: true,
            };
        }

        async componentDidMount() {
            try {
                setTimeout(() => {
                    this.setState({
                        loading: false,
                    });
                }, 1000)
            } catch (err) {
                console.log(err);
                this.setState({
                    loading: false,
                });
            }
        }

        render() {
            // while checking user session, show "loading" message
            if (this.state.loading) return LoadingMessage();

            // otherwise, show the desired route
            return <WrappedComponent {...this.props} />;
        }
    };
}

export default withSplashScreen;
