import React, { useState } from 'react';
import ScheduleTabsContent from "./ScheduleTabsContent";
import {BrowserRouter} from "react-router-dom";
import {parse} from "query-string"
import ScheduleTabHeader from "./ScheduleTabHeader";
import styled from "styled-components";


const days = ['Monday','Tuesday'];

const PanelTabs = styled.p`
  font-size: 1.1em;
  span {
    font-weight: 600;
  }
`

function ScheduleTabs({data, highlightedDays}) {

    const q = parse(window.location.search)
    let selectedTab = "Monday"

    if(q.day){
        if(days.includes(q.day)) {
            selectedTab = q.day
        }
    } else {
        const now = new Date()
        const confStart = new Date(2021, 3, 8);
        const confFinish = new Date(2021, 3, 9);
        if(now >= confStart && now <= confFinish){
            const d = now.getDay()
            selectedTab = days[d];
        }

    }


    const [day, setDay] = useState(selectedTab);


    return (
        <BrowserRouter>
            <PanelTabs className="panel-tabs is-centered">
                <ScheduleTabHeader day="Monday" activeDay={day} onClick={setDay} bold={highlightedDays[0]}/>
                <ScheduleTabHeader day="Tuesday" activeDay={day} onClick={setDay} bold={highlightedDays[1]}/>
                {/*<ScheduleTabHeader day="Wednesday" activeDay={day} onClick={setDay} bold={highlightedDays[2]}/>*/}
                {/*<ScheduleTabHeader day="Thursday" activeDay={day} onClick={setDay} bold={highlightedDays[3]}/>*/}
                {/*<ScheduleTabHeader day="Friday" activeDay={day} onClick={setDay} bold={highlightedDays[4]}/>*/}
            </PanelTabs>

            <ScheduleTabsContent data={data} activeDay={day}/>

        </BrowserRouter>
    );
}

export default ScheduleTabs
