import React from 'react'
import styled from "styled-components";

const ConfTitle = styled.p`
  font-size: 1.5em;
`

function Header(props){
    return (
        <ConfTitle className="panel-heading">
            <div className="columns">
                <div className="column">
                    KomPlasTech 2021 Schedule
                </div>
            </div>
        </ConfTitle>
    )
}

export default Header
