import React from 'react'
import { parseISO } from 'date-fns'
import { format, utcToZonedTime } from "date-fns-tz";

import styled from 'styled-components';
import {abstractAtom} from "../Atoms/abstract";
import {useAtom} from "jotai";


const Title = styled.p`
  text-decoration: ${props => props.canceled ? 'line-through' : 'none'};
`

const Authors = styled.p`
  font-size: 0.9em;
  font-weight: bold;
`

const TimeHeader = styled.td`
  width: 70px;
`

const Td = styled.td`
    padding: 0.5em 0em !important;
`

const FormatedLink = styled.a`
    color: #3273dc;
    &:visited, &:hover, &:active {
      color: #3273dc;
    }
`

const Tr = styled.tr`
  &:last-child td {
    border: none;
  }
`

const formatInTimeZone = (date, tz) =>
    format(utcToZonedTime(date, tz), 'H:mm',
        { timeZone: tz });


function SessionRow({presentation}){
    const {authors, date, title, canceled, content} = presentation
    const [, setAbstract] = useAtom(abstractAtom)

    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    const formattedDate = formatInTimeZone(parseISO(date), tz)
    const formattedAuthors = authors.join(", ")

    const showAbstract = (presentation) => {
        setAbstract(presentation);
    }

    const TextOrLink = ({title, content}) => {
        if(content)
            return <FormatedLink onClick={() => showAbstract(presentation)}>{title}</FormatedLink>
        else
            return <span>{title}</span>
    }

    return (
        <>
        <Tr>
            <TimeHeader>{formattedDate}</TimeHeader>
            <Td>
                <Title canceled={canceled}>
                    <TextOrLink title={title} content={content}/>
                </Title>
                <Authors>
                    {formattedAuthors}
                </Authors>
            </Td>
        </Tr>
        </>
    )
}

export default SessionRow
