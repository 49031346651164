import React from 'react'
import {Link} from "react-router-dom";
import styled from 'styled-components'

const StyledLink = styled.span`
  @media (max-width: 330px) {
    font-size: 0.795em;
  }
  
  font-weight: ${props => props.bold ? 'bold' : 'normal'};
`


function ScheduleTabHeader({day, activeDay, onClick, bold}) {

    return (
        <Link
            to={`?day=${day}`}
            onClick={() => onClick(day)}
            className={day === activeDay ? "is-active" : ""}
        >
            <StyledLink bold={bold}>{day}</StyledLink>
        </Link>
    )
}

export default ScheduleTabHeader