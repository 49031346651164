import React, {useState} from 'react'
import SearchBar from "./SearchBar";
import ScheduleTabs from "./ScheduleTabs";
import Header from "./Header";
import sourceData from "../data"
import Footer from "./Footer";
import {AbstractView} from "./Abstract";
import {Message} from "./Message";

const resetHighlights = () => Array(5).fill(false)


const TzInfo = () => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

    return (
        <p>All presentation times on this page are in time zone: <strong>{tz}</strong>. Check <a href="http://komplastech.agh.edu.pl/public_repo/schedule.pdf">PDF</a> for CET times. <br/>
            To join the conference room, click the link under the session name. The rooms are provided by ClickMeeting.

        </p>
    )
}

function Home(props) {

    const [content, setContent] = useState(sourceData);
    const [searchError, setSearchError] = useState(false);
    const [highlightedDays, setHighlightedDays] = useState(resetHighlights())
    const [searchMode, setSearchMode] = useState(false)


    const onSearchChange = (searchText) => {
        if (searchText === '') {
            let newContent = Object.assign({}, sourceData)
            setContent(newContent)
            setSearchError(false)
            setSearchMode(false)
            setHighlightedDays([...resetHighlights()])
        } else if (searchText && searchText.length >= 3) {
            let newHighlights = resetHighlights()
            let newContent = Object.assign({}, sourceData)
            let found = 0

            const filtered = newContent.presentations.filter((p) => {
                let stay = false
                const {title, authors, keywords} = p
                if (title.toLowerCase().includes(searchText.toLowerCase())) {
                    stay = true
                    found++
                } else if (keywords.some((key) => key.toLowerCase().includes(searchText.toLowerCase()))) {
                    stay = true
                    found++
                } else if (authors.some((author) => author.toLowerCase().includes(searchText.toLowerCase()))) {
                    stay = true
                    found++
                }

                if (stay) {
                    const date = new Date(p.date)
                    const dayFromDate = date.getDay() - 1
                    newHighlights[dayFromDate] = true
                }
                return stay
            })
            newContent.presentations = filtered
            setContent(newContent)
            if (found === 0) setSearchError(true)
            else setSearchError(false)

            setSearchMode(true)
            setHighlightedDays(newHighlights)
        }
    }



    return (
        <div className="container">
                    <div className="panel">
                        <Header/>
                        <SearchBar onSearchChange={onSearchChange} searchError={searchError}/>
                        <Message content={<TzInfo/>} />
                        <ScheduleTabs data={content} highlightedDays={highlightedDays} searchMode={searchMode}/>

                        <Footer/>
                    </div>
            <AbstractView/>
        </div>
    )
}

export default Home
