import styled from 'styled-components'

const FooterContainer = styled.footer`
  padding: 2rem 1.5rem 2rem;
`

const Footer = () => {
    return (
        <FooterContainer className="footer">
            <div className="content has-text-centered">
                <p>
                   <a href="http://www.komplastech.agh.edu.pl/"> <strong>KomPlasTech 2021</strong></a> - International Conference on Computer Methods in Materials Technology
                </p>
                <a rel="noopener noreferrer" target="_blank" href="https://www.krakow.pl/"><img src={process.env.PUBLIC_URL + '/Krakow.png'} alt="Krakow"/></a>
            </div>
        </FooterContainer>
    )
}

export default Footer