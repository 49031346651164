import React from 'react'
import SessionRow from "./SessionRow";
import crypto from "crypto"
import styled from 'styled-components';
import {PointerIcon} from "../Icons/Pointer";


const StyledHeader = styled.tr`
    min-height: 65px;
    height: 65px;
    font-variant: small-caps;
    font-size: 1.1em;
`

function Session({name, session, loc, locUrl}){
    if(!session) return null

    session.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
    })

    const rows = session.map(presentation => (
        <SessionRow
            key={crypto.createHash('sha1').update(presentation.title).digest('base64')}
            presentation={presentation}
        />)
    )

    return (

        <table className="table is-hoverable is-fullwidth">
            <thead>
            <StyledHeader >
                <th colSpan="2">
                    {name}<br/><a href={locUrl} target="_blank" rel="noopener noreferrer"><PointerIcon width={20} /> {loc}</a></th>
                <th></th>
            </StyledHeader>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </table>
   )
}

export default Session